/**
 * @author MrZenW
 * @email MrZenW@Gmail.com, https://MrZenW.com
 * @create date 2021-06-17 11:32:52
 * @modify date 2021-06-17 11:32:52
 * @desc [description]
 */
body.dark-theme {
  .work-view-modal-content {
    background-color: var(--theme-bg);
    color: var(--theme-color);
  }
}
