/* Entire Blockly area, Toolbox dranw on top */
body {
  .blocklySvg {
    border-width: 0 !important;
  }

  /* Toolbox */

  #playgroundBlocklyWorkspaceContainer {
    position: relative;
  }

  #playgroundBlocklyWorkspaceContainer .blocklyTreeRow {
    /* height: 34px;
    line-height: 34px; */
    height: 40px;
    line-height: 40px;
    padding-top: 0 !important;
  }
  .blocklyTreeSeparator {
    padding: 0 !important;
    margin: 0 !important;
  }

  .blocklyScrollbarBackground {
    stroke-width: 0px !important;
    fill: none !important;
  }



  /*-----------------------------
    Blockly customised styles
    ---------------------------*/
  .blocklyFlyoutLabelText {
    fill: green;
    font-size: 120%;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  .blocklyFlyoutLabelTextCategoryInSearchResult text {
    fill: green !important;
    font-size: 150% !important;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  .blocklyFlyoutLabelTextTitle text {
    fill: rgb(77, 77, 77) !important;
    font-size: 150% !important;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  div.blocklyTreeRow .blocklyTreeLabel {
    text-shadow: 0px 0px 15px white;
  }

  // flyout margin
  .injectionDiv .blocklyFlyout .blocklyWorkspace {
    transform: translateX(20px);
  }
}
