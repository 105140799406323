.control-panel-toolbar {
  .content-item-left {
    order: 1;
  }
  .content-item-centre {
    order: 2;
  }
  .content-item-right {
    order: 3;
  }
  @media (max-width: 600px) {
    .content-item-centre {
      order: 3;
    }
    .content-item-right {
      order: 2;
    }
  }
}