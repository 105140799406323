/* Entire Blockly area, Toolbox dranw on top */
body.light-theme {
  // :root {
  //   --workspace-bg-color: rgb(200, 200, 200);
  // }
  .blockly_background {
    background: rgba(255, 255, 255, 0.9);
  }
  .blocklySvg {
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .injectionDiv {
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  /* Toolbox */
  .blocklyToolboxDiv {
    box-shadow: rgb(136, 136, 136) 0px 0px 3px;
    background-color: rgba(249, 250, 251, .8) !important;
    // background-image: var(--theme-bg-gradient);
    overflow: visible;
    color: black;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .blocklyTreeRow:not(.blocklyTreeSelected):hover {
    background-color: rgba(200, 200, 200, 0.5) !important;
    // background-color: rgba(255, 204, 51, 1) !important;
    /* arduino yellow */
  }

  /* Toolbox second column with blocks*/
  .blocklyFlyoutBackground {
    fill: rgba(199, 199, 199, 0.8) !important;
  }

  /* Blockly scrollbars */
  .blocklyScrollbarKnob {
    fill: #00878F !important;
    fill-opacity: 0.7 !important;
  }

  /*-----------------------------
    Blockly customised styles
    ---------------------------*/

  .blocklyFlyoutLabelText {
    fill: green;
    font-size: 120%;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  .blocklyFlyoutLabelTextCategoryInSearchResult text {
    fill: green !important;
    font-size: 150% !important;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  .blocklyFlyoutLabelTextTitle text {
    fill: rgb(77, 77, 77) !important;
    font-size: 150% !important;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  }

  /* Toolbox */

  div.blocklyTreeRow .blocklyTreeLabel {
    text-shadow: 0px 0px 15px white;
  }

}
