body.light-theme {
  .web-window {
    border: 1px solid rgb(208, 208, 208);
    // background-image: var(--theme-bg-gradient);
    .web-window-overlayer {
      background-color: white;
      // opacity: 0.5;
      opacity: 0;
    }
  }
  .web-window.unfocus {
    box-shadow: rgba(100, 100, 100, 1) 0px 0px 5px;
    .web-window-title {
      background-color: rgb(246, 246, 246);
      color: gray;
    }
  }
  .web-window.focus {
    box-shadow: rgba(100, 100, 100, 1) 0px 0px 15px;
  }
}
