.grecaptcha-badge {
  display: none;
}


#root {
  width: 100vw;
  height: 100vh;
  background-color: var(--theme-bg);
}

@import "./common-spin.scss";
@import "./common--light-theme.scss";
@import "./common--dark-theme.scss";
@import "./hover-effect.scss";


.high-contrast-style {
  filter: contrast(200%);
}

.radius-cycle {
  border-radius: 50%;
}

// driver.js
#driver-popover-item, #driver-popover-item *:not(:first-child):no-button {
  background-color: var(--theme-bg) !important;
  color: var(--theme-color) !important;
}

.driver-popover-tip {
  .left {
    border-color: transparent var(--theme-bg) transparent !important;
  }
  .right {
    border-color: transparent transparent transparent var(--theme-bg) !important;
  }
  .top {
    border-color: transparent transparent var(--theme-bg) !important;
  }
  .bottom {
    border-color: var(--theme-bg) transparent !important;
  }
}
