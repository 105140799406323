body.light-theme {
  .swal2-container {
    z-index: 3050;
  }
  // .swal2-popup {
  //   background: rgba(215, 215, 215, 0.8);
  //   border: 1px solid rgb(215, 215, 215);
  //   color: var(--theme-color);
  //   backdrop-filter: saturate(180%) blur(20px);
  //   // box-shadow: 0px 0px 10px rgba(45, 45, 45, 0.5);
  // }
  // .swal2-title {
  //   color: #3a3a3a;
  // }
}
