body.light-theme {
  .settings-menu-tooltip {
    display: inline-block;
    background: rgba(255, 255, 255, 0.9);
    color: var(--theme-color);
    // font-weight: bold;
    // padding: 5px 10px;
    // font-size: 13px;
    // border-radius: 0px;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 0px 20px black;
    backdrop-filter: saturate(180%) blur(20px);
  }
  .settings-menu-view-container {
    // background: #ffffff;
  }
}
body.dark-theme {
  .settings-menu-tooltip {
    display: inline-block;
    background: rgba(33, 33, 33, 0.9);
    color: var(--theme-color);
    // font-weight: bold;
    // padding: 5px 10px;
    // font-size: 13px;
    border: 1px solid #606060;
    box-shadow: 0px 0px 20px white;
    backdrop-filter: saturate(180%) blur(20px);
  }
  .settings-menu-view-container {
    // background: rgb(33, 33, 33);
  }
}
.settings-menu-view-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

body.light-theme {
  .settings-menu-arrow,
  .settings-menu-arrow::before {
    position: absolute;
    width: 0px;
    height: 0px;
    background: inherit;
    border-top: 5px solid rgba(0, 0, 0, 0);
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(255, 255, 255, 0.9);
    border-bottom: 5px solid rgba(255, 255, 255, 0.9);
    background-color: unset;
  }
}

body.dark-theme {
  .settings-menu-arrow,
  .settings-menu-arrow::before {
    position: absolute;
    width: 0px;
    height: 0px;
    background: inherit;
    border-top: 5px solid rgba(0, 0, 0, 0);
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(33, 33, 33, 0.9);
    border-bottom: 5px solid rgba(33, 33, 33, 0.9);
    background-color: unset;
  }
}

.settings-menu-arrow {
  visibility: hidden;
}

.settings-menu-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

// .settings-menu-tooltip[data-popper-placement^='top'] > .settings-menu-arrow {
//   bottom: -6px;
// }

// .settings-menu-tooltip[data-popper-placement^='bottom'] > .settings-menu-arrow {
//   top: -6px;
// }

// .settings-menu-tooltip[data-popper-placement^='left'] > .settings-menu-arrow {
//   right: -6px;
// }

// .settings-menu-tooltip[data-popper-placement^='right'] > .settings-menu-arrow {
//   left: -6px;
// }
