/* Entire Blockly area, Toolbox dranw on top */
body.dark-theme {
  // :root {
  //   --workspace-bg-color: rgb(100, 100, 100);
  // }
  .blockly_background {
    background: rgba(33, 33, 33, 0.9);
  }
  .blocklySvg {
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .injectionDiv {
    // background-image: var(--theme-bg-gradient) !important;
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  /* Toolbox */
  .blocklyToolboxDiv {
    box-shadow: rgb(136, 136, 136) 0px 0px 3px;
    background-color: rgb(58, 58, 58) !important;
    // background-image: var(--theme-bg-gradient);
    overflow: visible;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .blocklyTreeRow:not(.blocklyTreeSelected):hover {
    // background-color: rgba(255, 204, 51, 1) !important;
    /* arduino yellow */
    background-color: rgba(100, 100, 100, 0.5) !important;
  }

  /* Toolbox second column with blocks*/
  .blocklyFlyoutBackground {
    fill: rgba(0, 0, 0, 0.8) !important;
  }

  /* Blockly scrollbars */
  .blocklyScrollbarKnob {
    fill: #00878F !important;
    fill-opacity: 0.7 !important;
  }

  /*-----------------------------
    Blockly customised styles
    ---------------------------*/

  .blocklyFlyoutLabelText {
    fill: rgb(181, 255, 181) !important;
    font-size: 120%;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 rgb(0, 0, 0), 0 1px rgb(0, 0, 0), 1px 0 rgb(0, 0, 0), 0 -1px rgb(0, 0, 0);
  }

  .blocklyFlyoutLabelTextCategoryInSearchResult text {
    fill: rgb(181, 255, 181) !important;
    font-size: 150% !important;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 rgb(0, 0, 0), 0 1px rgb(0, 0, 0), 1px 0 rgb(0, 0, 0), 0 -1px rgb(0, 0, 0);
  }

  .blocklyFlyoutLabelTextTitle text {
    fill: rgb(216, 216, 216) !important;
    font-size: 150% !important;
    font-family: Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
    text-shadow: -1px 0 rgb(0, 0, 0), 0 1px rgb(0, 0, 0), 1px 0 rgb(0, 0, 0), 0 -1px rgb(0, 0, 0);
  }

  /* Toolbox */

  div.blocklyTreeRow .blocklyTreeLabel {
    text-shadow: 0px 0px 15px rgb(0, 0, 0);
  }

}
