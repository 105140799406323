body.dark-theme {
  .swal2-container {
    z-index: 3050;
  }
  .swal2-popup {
    background: rgba(45, 45, 45, 0.8);
    border: 1px solid rgb(96, 96, 96);
    color: var(--theme-color);
    backdrop-filter: saturate(180%) blur(20px);
    // box-shadow: 0px 0px 10px rgba(215, 215, 215, 0.5);
  }
  .swal2-title {
    color: rgb(218, 218, 218);
  }
  .swal2-content {
    color: #cccccc;
  }
  .swal2-timer-progress-bar {
    background: rgba(255, 255, 255, 0.5) !important;
  }
}
