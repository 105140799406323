body.dark-theme {
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
  }
  
  ::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(0,0,0,0);
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(100, 100, 100);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(128, 128, 128);
  }
}
